<template>
  <div class="section">

    <!--section-->

    <div style="background: rgb(187 116 148 / 18%); height: 450px;" id="desktopTemplate">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 text-center">
            <img width="500" src="https://terapivitrini.com/assets/images/aboutus/kapak.png" />
          </div>
          <div class="col-lg-6 col-md-12 pt-5 mt-7">
            <div class="widgets-cards">
              <div class="card-body d-flex">
                <div class="widgets-cards-data">
                  <div class="wrp text-wrapper">
                    <h1 style="font-size: 32px; font-weight: 500;">Terapi Vitrini</h1>
                    <br />
                    <p class="text-muted mt-1 mb-0" style="
                          line-height: 30px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                      Terapi Vitrini, terapi hizmetlerine ihtiyaç duyanlar ile terapistleri bir araya getirmek üzere
                      tasarlanmış, erişimi ve kullanımı kolay bir platformdur.
                    </p>
                    <br /><br />
                    <h4>
                      <b>Terapi erişimini güvenli, hızlı ve kolay hale getiriyoruz.</b>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: rgb(187 116 148 / 18%); height: 560px;" id="mobileTemplate">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <img width="250" src="https://terapivitrini.com/assets/images/aboutus/kapak.png" />
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="widgets-cards">
              <div class="card-body d-flex">
                <div class="widgets-cards-data">
                  <div class="wrp text-wrapper">
                    <p style="font-size: 32px; font-weight: 500;">Terapi Vitrini</p>
                    <br />
                    <p class="text-muted mt-1 mb-0" style="
                          line-height: 30px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                      Terapi Vitrini, terapi hizmetlerine ihtiyaç duyanlar ile terapistleri bir araya getirmek üzere
                      tasarlanmış, erişimi ve kullanımı kolay bir platformdur.
                    </p>
                    <br /><br />
                    <h4>
                      <b>Terapi erişimini güvenli, hızlı ve kolay hale getiriyoruz.</b>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container mb-5 mt-5">
        <div class="">
          <div class="row" id="desktopTemplate">

            <div class="col-lg-6 col-md-12" style="padding-top:0px !important">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Terapist Hesabı Oluşturma</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist, kişisel profilini kolay bir şekilde oluşturabilir ve yönetebilir.
                        Terapi Vitrininde açmış olduğunuz profilinizde; özgeçmişinizi, iletişim bilginizi, çalışma
                        saatlerinizi, adresinizi, sertifikalarınızı, ilgi alanlarınızı, fotoğraflarınızı, makalelerinizi,
                        videolarınızı ekleyebilir, terapi arayanların sizinle ilgili detayları öğrenmelerini
                        sağlayabilirsiniz. Ve böylelikle Terapist arayanlara, bilgilerinizi direkt sunabilir ve karar alma
                        süreçlerini kolaylaştırabilirsiniz.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 text-center mt-2">
              <img src="https://terapivitrini.com/assets/images/aboutus/1randevu-olusturma.png" />
            </div>

            <div class="col-lg-6 col-md-12 text-center">
              <img src="https://terapivitrini.com/assets/images/aboutus/2randevu-takvimi.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Randevu Takvimi</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Danışanlarınız için randevu almayı kolaylaştırın. Randevu takvimini etkinleştirin ve
                        danışanlarınızın online randevu almalarını sağlayın. Terapi arayan danışanlar, profilinizden 7/24
                        randevularını oluşturabilir. Randevu takvimi sayesinde randevularınızı kolayca takip edebilir ve
                        yönetibilirsiniz.
                      </p>

                      <br /><br />
                      <h4>
                        <b style="line-height:30px"> Terapi vitrini sağlığınıza yardımcı olur.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 pt-7">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">
                        Randevu Hatırlatma Servisi
                      </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Randevular belirli aralıklarla hatırlatma servisi ile danışanlara iletilir. Randevu öncesi
                        danışanlarınıza, randevunun unutulmaması için hatırlatma mesajları gönderilir.
                      </p>
                      <br />
                      <h4>
                        <b style="line-height:30px"> Terapi vitrini lisanslı bir terapistle bağlantı kurmanın
                          en uygun ve ekonomik yoludur.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-5">
              <img src="https://terapivitrini.com/assets/images/aboutus/3Randevu-Hatırlatma.png" />
            </div>

            <div class="col-lg-6 col-md-12 text-center">
              <img src="https://terapivitrini.com/assets/images/aboutus/4-yuzyuze-online.png" />
            </div>
            <div class="col-lg-6 col-md-12 mt-7">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Yüz Yüze / Online / Evde Terapi</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist, yüz yüze, online ve evde terapi hizmeti sunma seçeneğini tercih edebilir.
                      </p>
                      <br /><br />
                      <h4>
                        <b style="line-height:30px">Geçmişi çözmek, bugünü ele almak ve daha iyi bir gelecek
                          inşa etmek için terapi vitrininden destek alabilirsiniz.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 pt-5 mt-5">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Yüz Yüze / Online / Video Eğitim </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapi Vitrininde çeşitli (Online, Video, Yüz yüze) eğitimler, kurslar ve etkileşimli web
                        seminerleri, alanında uzman terapistler tarafından hazırlanır. Eğitimler, bireylerin,
                        öğrencilerin, profesyonellerin, kurumların ihtiyaçlarına göre terapistler tarafından tasarlanmakta
                        ve paylaşılmaktadır. Sizde eğitimlerinizi, kurslarınızı, seminerlerinizi Terapi Vitrini üzerinden
                        düzenleyebilir ve paylaşabilirsiniz.
                        <br /><br />
                      <h4><b>Terapi vitrini, terapiyi herkes için erişilebilir hale getirir.</b></h4>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-7">
              <img src="https://terapivitrini.com/assets/images/aboutus/5-egitim.png" />
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/6danisantakibi.png" />
            </div>
            <div class="col-lg-6 col-md-12 mt-6">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">
                        Danışan Takip Sistemi
                      </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Tüm danışanlarınızın işlemlerini tek bir yerde toplayarak kolayca yönetmenizi sağlar.
                        Danışanlarınıza ait temel bilgileri, açıklamaları ve notları kaydedin.
                        Geçmiş ve gelecek terapileri tüm detayları ve belgeleri ile kayıt altında tutun, bu kayıtlara her
                        yerden ulaşın.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 mt-5">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper ">
                      <p style="font-size: 32px; font-weight: 500;">Danışan Görüşleri</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapi seçiminde yorumların önemsenme oranı %80 gibi yüksek bir seviyededir. Danışanlarınıza
                        hizmet sonrası otomatik gönderilen mesaj ile değerlendirme(yorum) isteyerek online itibarınızı
                        kolayca oluşturun.
                      </p>
                      <br />
                      <h4>
                        <b>Terapi Vitrini ile 7/24 lisanslı profesyonel bir terapiste erişim sağlayabilirsiniz.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-5">
              <img src="https://terapivitrini.com/assets/images/aboutus/7-danisangorusleri.png" />
            </div>

            <div class="col-lg-6 col-md-12 mt-5">
              <img src="https://terapivitrini.com/assets/images/aboutus/8-onlineodeme.png" />
            </div>
            <div class="col-lg-6 col-md-12 mt-5">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Online Ödeme Sistemi</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist ödeme almak için danışanlarına, tek çekimden 12 takside kadar yerli ve yabancı kartla
                        ödeme seçeneği sunabilmektedir.
                      </p>
                      <br /><br />
                      <h4>
                        <b style="line-height:30px">Siz terapinize odaklanın müşteri edinme, danışan takibi, destek ve
                          diğer operasyonlarını bize bırakın</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 pt-7 mt-7">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Dinamik Google Facebook & Instagram Reklamları</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist olarak üye olmanız durumunda Google Facebook & Instagram üzerinde sponsorlu Terapi
                        Vitrini reklamları arasında yerinizi alabilirsiniz. Profiliniz Google üzerinden yapılan aramalarda
                        ulaşılabilir hale gelir.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-7">
              <img src="https://terapivitrini.com/assets/images/aboutus/9google.png" />
            </div>

            <div class="col-lg-6 col-md-12 mt-7">
              <img src="https://terapivitrini.com/assets/images/aboutus/10cagri.png" />
            </div>
            <div class="col-lg-6 col-md-12 mt-7 pt-7">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">
                        Çağrı Merkezi Desteği
                      </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Çağrı merkezi desteği sayesinde danışanlar Terapi Vitrinine ulaşıp randevu ve bilgi talep
                        edebilirler. Terapi Vitrini çağrı merkezi, terapi arayan danışanları terapistlere yönlendirir.
                      </p>
                      <br />
                      <h4>
                        <b>
                          Terapi Vitrini, terapi hizmetini güvenilir bir yapı içinde sunar.
                        </b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 mt-8">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Terapist Profil İstatistiği</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Profil ziyareti, alınan randevu, onaylanan randevu, reddedilen randevu, telefon araması ve yorum
                        istatistiğini görebilirsiniz.
                      </p>
                      <br />
                      <h4>
                        <b>Terapi Vitrini, terapi deneyiminizi kişiselleştirmenin harika bir yoludur.</b>
                      </h4>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-5">
              <img src="https://terapivitrini.com/assets/images/aboutus/11istatisk.png" />
            </div>
          </div>

          <div class="row" id="mobileTemplate">
            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/1randevu-olusturma.png" />
            </div>
            <div class="col-lg-6 col-md-12" style="padding-top:0px !important">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Terapist Hesabı Oluşturma</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist, kişisel profilini kolay bir şekilde oluşturabilir ve yönetebilir.
                        Terapi Vitrininde açmış olduğunuz profilinizde; özgeçmişinizi, iletişim bilginizi, çalışma
                        saatlerinizi, adresinizi, sertifikalarınızı, ilgi alanlarınızı, fotoğraflarınızı, makalelerinizi,
                        videolarınızı ekleyebilir, terapi arayanların sizinle ilgili detayları öğrenmelerini
                        sağlayabilirsiniz. Ve böylelikle Terapist arayanlara, bilgilerinizi direkt sunabilir ve karar alma
                        süreçlerini kolaylaştırabilirsiniz.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/2randevu-takvimi.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Randevu Takvimi</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Danışanlarınız için randevu almayı kolaylaştırın. Randevu takvimini etkinleştirin ve
                        danışanlarınızın online randevu almalarını sağlayın. Terapi arayan danışanlar, profilinizden 7/24
                        randevularını oluşturabilir. Randevu takvimi sayesinde randevularınızı kolayca takip edebilir ve
                        yönetibilirsiniz.
                      </p>
                      <br /><br />
                      <h4>
                        <b style="line-height:30px"> Terapi vitrini sağlığınıza yardımcı olur.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/3Randevu-Hatırlatma.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">
                        Randevu Hatırlatma Servisi
                      </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Randevular belirli aralıklarla hatırlatma servisi ile danışanlara iletilir. Randevu öncesi
                        danışanlarınıza, randevunun unutulmaması için hatırlatma mesajları gönderilir.
                      </p>
                      <br />
                      <h4>
                        <b style="line-height:30px"> Terapi vitrini lisanslı bir terapistle bağlantı kurmanın
                          en uygun ve ekonomik yoludur.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/4-yuzyuze-online.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Yüz Yüze / Online / Evde Terapi</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist, yüz yüze, online ve evde terapi hizmeti sunma seçeneğini tercih edebilir.
                      </p>
                      <br /><br />
                      <h4>
                        <b style="line-height:30px">Geçmişi çözmek, bugünü ele almak ve daha iyi bir gelecek
                          inşa etmek için terapi vitrininden destek alabilirsiniz.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/5-egitim.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Yüz Yüze / Online / Video Eğitim </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapi Vitrininde çeşitli (Online, Video, Yüz yüze) eğitimler, kurslar ve etkileşimli web
                        seminerleri, alanında uzman terapistler tarafından hazırlanır. Eğitimler, bireylerin,
                        öğrencilerin, profesyonellerin, kurumların ihtiyaçlarına göre terapistler tarafından tasarlanmakta
                        ve paylaşılmaktadır. Sizde eğitimlerinizi, kurslarınızı, seminerlerinizi Terapi Vitrini üzerinden
                        düzenleyebilir ve paylaşabilirsiniz.
                        <br /><br />
                      <h4><b>Terapi vitrini, terapiyi herkes için erişilebilir hale getirir.</b></h4>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/6danisantakibi.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">
                        Danışan Takip Sistemi
                      </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Tüm danışanlarınızın işlemlerini tek bir yerde toplayarak kolayca yönetmenizi sağlar.
                        Danışanlarınıza ait temel bilgileri, açıklamaları ve notları kaydedin.
                        Geçmiş ve gelecek terapileri tüm detayları ve belgeleri ile kayıt altında tutun, bu kayıtlara her
                        yerden ulaşın.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/7-danisangorusleri.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper ">
                      <p style="font-size: 32px; font-weight: 500;">Danışan Görüşleri</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapi seçiminde yorumların önemsenme oranı %80 gibi yüksek bir seviyededir. Danışanlarınıza
                        hizmet sonrası otomatik gönderilen mesaj ile değerlendirme(yorum) isteyerek online itibarınızı
                        kolayca oluşturun.
                      </p>
                      <br />
                      <h4>
                        <b>Terapi Vitrini ile 7/24 lisanslı profesyonel bir terapiste erişim sağlayabilirsiniz.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/8-onlineodeme.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Online Ödeme Sistemi</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist ödeme almak için danışanlarına, tek çekimden 12 takside kadar yerli ve yabancı kartla
                        ödeme seçeneği sunabilmektedir.
                      </p>
                      <br /><br />
                      <h4>
                        <b style="line-height:30px">Siz terapinize odaklanın müşteri edinme, danışan takibi, destek ve
                          diğer operasyonlarını bize bırakın.</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/9google.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Dinamik Google Facebook & Instagram Reklamları</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Terapist olarak üye olmanız durumunda Google Facebook & Instagram üzerinde sponsorlu Terapi
                        Vitrini reklamları arasında yerinizi alabilirsiniz. Profiliniz Google üzerinden yapılan aramalarda
                        ulaşılabilir hale gelir.</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/10cagri.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">
                        Çağrı Merkezi Desteği
                      </p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Çağrı merkezi desteği sayesinde danışanlar Terapi Vitrinine ulaşıp randevu ve bilgi talep
                        edebilirler. Terapi Vitrini çağrı merkezi, terapi arayan danışanları terapistlere yönlendirir.
                      </p>
                      <br />
                      <h4>
                        <b>
                          Terapi Vitrini, terapi hizmetini güvenilir bir yapı içinde sunar.
                        </b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <img src="https://terapivitrini.com/assets/images/aboutus/11istatisk.png" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="widgets-cards">
                <div class="card-body d-flex">
                  <div class="widgets-cards-data">
                    <div class="wrp text-wrapper">
                      <p style="font-size: 32px; font-weight: 500;">Terapist Profil İstatistiği</p>
                      <br />
                      <p class="text-muted mt-1 mb-0" style="
                          line-height: 35px;
                          font-size: 18px;
                          text-align: justify;
                        ">
                        Profil ziyareti, alınan randevu, onaylanan randevu, reddedilen randevu, telefon araması ve yorum
                        istatistiğini görebilirsiniz.
                      </p>
                      <br />
                      <h4>
                        <b>Terapi Vitrini, terapi deneyiminizi kişiselleştirmenin harika bir yoludur.</b>
                      </h4>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </section>
    <div style="background: rgb(187 116 148);" class="p-6">
      <div class="container text-center text-white">
        <h2 style="font-size:37px">Profesyonel Üyelik</h2>
        <h2>ÜCRETSİZ</h2>
        <button @click="register" class="btn buttonRegister"><b>Terapist Olarak Kaydol</b></button>
      </div>
    </div>
  </div>
</template>
  <!--Start of Tawk.to Script-->

<!--End of Tawk.to Script-->
<style>
@media screen and (max-width: 990px) {
  #mobileTemplate {
    display: flex !important;
  }

  #desktopTemplate {
    display: none !important;
  }
}

.buttonRegister {
  background: #fff;
  color: rgb(187 116 148);
}

#mobileTemplate {
  display: none;
}

#desktopTemplate {
  display: flex;
}

.card-body+.card-body {
  border: none;
}

.carExpert {
  box-shadow: 0px 10px 13px -12px #bebebe;
  border-radius: 7px;
  border: none;
}

.horizontal-main {
  background: rgb(243 231 235);
  box-shadow: none;
}

.horizontalMenu-list {
  background: none !important;
}

.section {
  background: white;
}
</style>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: "Terapist Üyelik Tercihleri - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Sık Sorulan Sorular - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "Terapist Üyelik Tercihleri - Terapi Vitrini",
      "Terapist Üyelik Tercihleri - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
  },
  data() {
    return {};
  },
  components: { Breadcrumb },
  methods: {
    register() {
      if (!this.$store.state.isLogin) {
        this.$router.push("/expert/register");
      } else {
        this.$vToastify.error("Zaten giriş yapmışsınız!", "Dikkat!");
      }
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  mounted() {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/63ac17f547425128790a7159/1glc3r6g9";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
    this.backToTop();
  },
};
</script>